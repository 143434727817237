
import htmx from "htmx.org";
window.htmx = htmx
window.htmx.config.globalViewTransitions = true;


import posthog from 'posthog-js'
window.posthog = posthog
if (true) {
    posthog.init('phc_Bhc0qd4wv8Y3bpMs3GojBsjibe6fq2fzjyayR05JVIs', {"api_host":"https://r2.sabal.finance/phg/","disable_session_recording":false,"autocapture":false})
}